.footer {
  color: #cbcbcb;
  position: relative;
}

.upper-footer {
  align-items: center;
  background: #555;
}

.upper-footer .menu {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.upper-footer .menu .menu-item {
  font-size: .875rem;
  width: 50%;
  float: left;
}

.upper-footer .menu .menu-item:last-child {
  margin-right: 0;
}

.upper-footer .menu .menu-item a {
  color: #fff;
}

.search-form {
  padding: 0 1rem;
  position: relative;
}

.search-form label {
  padding: 0;
}

.search-form .search-field {
  border: 1px solid #f5a623;
}

.search-form .search-submit, .search-form .search-submit:hover  {
  background-color: #f5a623;
  position: relative;
}

.search-form:after {
  content: '';
  background: url(/app/themes/fusio/dist/images/001-search.svg);
  position: absolute;
  height: 1rem;
  width: 1rem;
  right: 10%;
}

.sub-footer {
  background: #333;
}

.sub-footer a {
  color: #cbcbcb;
}

.footer-brand,
.social-media,
span.footer-line {
  display: inline-block;
  float: left;
}

.footer-brand {
  height: 2rem;
  width: auto;
}

span.footer-line {
  border: 2px solid #f5a623;
  margin: 1rem;
  margin: calc(1rem - 1px) 1rem;
  width: 3rem;
}

footer .search-form .search-submit, 
footer .search-form .search-submit:hover {
  border: 1px solid #f5a623;
}

.social-media {
  list-style: none;
  padding: 0;
}

.social-media li {
  float: left;
  padding-top: .125rem;
}

.social-media li img {
  height: 1rem;
  width: auto;
}

.social-media li+li {
  margin-left: 1rem;
}

.search-form label,
footer address {
  margin-bottom: 0;
}

@media (min-width: 768px) {

  .upper-footer {
    align-items: center;
    background: #555;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
  }

  .upper-footer .menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .upper-footer .menu .menu-item {
    float: initial;
    margin-right: 1.5rem;
    width: initial;
  }

  .footer-brand {
    height: 2.5rem;
  }

  span.footer-line {
    margin: 2rem 1rem;
    margin: calc(1.25rem - 1px) 1rem;
    width: 6rem;
  }

  .social-media li {
    padding-top: .5rem;
  }

  .social-media li+li {
    margin-left: 1.5rem;
  }

  .social-media li img {
    height: 1.5rem;
  }

}
