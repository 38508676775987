/* Posts > Common */

.post-header {
	background: #393939;
}

.post-subtitle {
	color: #F5A623;
	font-size: 1.75rem;
	font-weight: 300;
	line-height: 1.375;
}

.post-header a {
	color: #fff;
	font-size: 1.125rem;
	font-weight: 600;
}

.post-image {
	margin-top: -5rem;
}

.entry-content a {
	font-weight: 600;
	text-decoration: underline;
}

@media (min-width: 375px) {
	.post-subtitle {
		font-size: 2rem;
	}
}

/* Posts > Jobs */

.jobs-image {
	border-radius: 50%;
	overflow: hidden;
}

.jobs-image-wrap img {
	max-height: 12rem;
}

/* Posts > Projects */

.project-hero {
	position: relative;
	height: 320px;
	height: 20rem;
}

@media (min-width: 1200px) {
	.project-hero {
		position: relative;
		height: 500px;
		height: 31.25rem;
	} 	
}

.project-hero .project-client-logo {
	max-height: 4rem;
}

.project-hero .project-client-logo.white-logo {
	filter: brightness(0) invert(1);
}

.project-intro a,
.project-intro p {
	line-height: 1.875;
}

.project-intro a {
	font-size: 0.875rem;
	color: #333;
}

@media (min-width: 768px) {
	.project-intro a {
		font-size: 1rem;
	}
}

.project-intro a:hover {
	text-decoration: none;
}

.desktop-project-image {
	margin-top:-5.625rem
}

@media (min-width: 1200px) {
	.desktop-project-image {
		margin-top:-15.625rem
	}
}

.portfolio-device {
	margin-top: -96px;
	margin-top: -6rem;
}

.portfolio-desktop,
.portfolio-mobile {
	background-repeat: no-repeat;
	position: relative;
}

.portfolio-desktop {
	background-image: url(/app/themes/fusio/dist/images/Apple-Macbook-Pro-13.png);
	background-size: 100%;
	height: 33rem;
}

.portfolio-desktop .img-container,
.portfolio-mobile .img-container {
	overflow: hidden;
}

.portfolio-desktop .img-container {
	max-height: 18.5rem;
	position: absolute;
}

.portfolio-desktop img {
	width: 100%;
}

.portfolio-mobile {
	background-image: url(/app/themes/fusio/dist/images/Apple-iPhone-7-Matte-Black.png);
	background-position: top left;
	background-size: 100%;
	height: 320px;
	height: 20rem;
	width: 144px;
	width: 9rem;
}

.portfolio-mobile .img-container {
	max-height: 220px;
	max-height: 13.75rem;
	max-width: 124px;
	max-width: 7.75rem;
	position: absolute;
	margin-left: 10px;
	top: 38px;
}

.portfolio-mobile img {
	width: 100%;
}

.portfolio-mobile.app {
	height: 25rem;
	width: 12rem;
}

.portfolio-mobile.app .img-container {
	margin-left: 14px;
	max-height: 18.25rem;
	max-width: 10.3rem;
	top: 50px;
}

.projects .entry-content {
	font-size: 1.25rem;
	font-weight: 400;
}

ul.services {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

ul.services li {
	float: left;
	width: 33.333333%;
}

ul.services li:last-child {
	margin-right: 0;
}

ul.services li img {
	height: 2.25rem;
	margin-bottom: 1rem;
	width: auto;
}

ul.services li h5 {
	margin-bottom: 0;
}

.project-text {
	background: #cbcbcb;
	padding: 5rem 0;
}

.project-feature-img {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 32rem;
}

.other-work {
	overflow: hidden;
	position: relative;
}

@media (min-width: 768px) {

	.portfolio-desktop {
		height: 320px;
		height: 20rem;
		min-width: 540px;
		min-width: 33.75rem;
	}

	.portfolio-desktop .img-container {
		max-height: 254.4px;
		max-height: 15.9rem;
		padding: 0 1rem;
		width: 27.4rem;
		top: 21.6px;
		top: 1.35rem;
		left: 51.2px;
		left: 3.2rem;
	}

	ul.services li img {
		height: 4rem;
	}
}

@media (min-width: 1200px) {

	.portfolio-desktop {
		height: 20rem;
	}

}

.goal-list {
	padding: 0;
}

.goal-list li {
	padding-top: 2rem;
	display: block;
	position: relative;
	counter-increment: a;
}

.goal-list li:before {
	background-color: #f5a623;
	content: counter(a);
	color: #000;
	font-size: 0.75rem;
	font-weight: 700;
	border-radius: 50%;
	border: 2px solid #000;
	text-align: center;
	top: 0;
	height: 1.5rem;
	width: 1.5rem;
	position: absolute;
	z-index: 0;
}

@media (min-width: 768px) {
	.goal-list li {
		padding-top: 3rem;
	}

	.goal-list li:before {
		font-size: 1rem;
		height: 2rem;
		width: 2rem;
		z-index: 0;
	}
}

/********************************

Staff Post  *********************************/

.staff-image {
	border-radius: 50%;
	overflow: hidden;
}

.staff-image-wrap img {
	max-height: 18.75rem;
}

.staff-contact {
	list-style: none;
	margin: 2.5rem 0;
	overflow: hidden;
	padding: 0;
	text-align: center;
}

.staff-contact li {
	display: inline-block;
	padding: 0 1rem;
}


/* Styles from here: https://tympanus.net/Development/ArrowNavigationStyles/ */

.svg-wrap {
	position: absolute;
	width: 0px;
	height: 0px;
	overflow: hidden;
}

svg:not(:root) {
	fill: white;
	overflow: hidden;
}

.other-work nav a {
	position: absolute;
	top: 50%;
	display: block;
	outline: none;
	text-align: left;
	z-index: 8;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.other-work nav a.prev {
	left: 0;
}

.other-work nav a.next {
	right: 0;
}

.other-work nav a svg {
	display: block;
	margin: 0 auto;
	padding: 0;
}

.nav-doubleflip {
	position: relative;
}

.nav-doubleflip a {
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.nav-doubleflip a.prev {
	-webkit-perspective-origin: 100% 50%;
	perspective-origin: 100% 50%;
}

.nav-doubleflip a.next {
	-webkit-perspective-origin: 0% 50%;
	perspective-origin: 0% 50%;
}

.nav-doubleflip .icon-wrap {
	position: relative;
	z-index: 100;
	display: block;
	width: 130px;
	height: 100px;
	background-color: #16a085;
	-webkit-transition: background-color 0.3s 0.3s;
	transition: background-color 0.3s 0.3s;
}

.nav-doubleflip svg.icon {
	position: relative;
	top: 50%;
	-webkit-transition: fill 0.3s 0.3s;
	transition: fill 0.3s 0.3s;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	fill: #000;
}

.nav-doubleflip div {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: -webkit-transform 0.3s 0.3s;
	transition: transform 0.3s 0.3s;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.nav-doubleflip a.prev div,
.nav-doubleflip a.prev h3 {
	left: 100%;
	-webkit-transform: rotateY(90deg);
	transform: rotateY(90deg);
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
}

.nav-doubleflip a.next div,
.nav-doubleflip a.next h3 {
	right: 100%;
	-webkit-transform: rotateY(-90deg);
	transform: rotateY(-90deg);
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
}

.nav-doubleflip img,
.nav-doubleflip h3 {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.nav-doubleflip h3 {
	margin: 0;
	padding: 1.75em 1em 0;
	background-color: #16a085;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
	font-style: italic;
	font-size: 1.15em;
	line-height: 1;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.nav-doubleflip a:hover .icon-wrap {
	background-color: #fff;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

.nav-doubleflip a:hover svg.icon {
	fill: #47c9af;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

.nav-doubleflip a:hover div,
.nav-doubleflip a:hover h3 {
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
}

.nav-doubleflip a:hover div {
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

.nav-doubleflip a:hover h3 {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}


/************************************************

Amended styles - only edit and amend after here 

************************************************/

.nav-doubleflip .icon-wrap {
	background-color: #f5a623;
	height: 6rem;
	width: 45vw;
}

.nav-doubleflip svg.icon {
	fill: #000;
}

.nav-doubleflip .icon-wrap p {
	color: #000;
}

.nav-doubleflip a:hover {
	text-decoration: none;
}

.nav-doubleflip img {
	object-fit: cover;
	object-position: 50%;
	/* positioned top left of the content box */
}

.nav-doubleflip a:hover svg.icon {
	fill: #f5a623;
}

.nav-doubleflip h3 {
	background-color: #f5a623;
	color: #333;
	font-size: 1rem;
	font-style: normal;
	line-height: 1.375;
	letter-spacing: 0;
	padding: 1rem 1rem 0;
}

.other-work-btnwrap {
	background-size: cover;
	height: 9rem;
	position: relative;
}

.other-work-btnwrap,
.other-work-btnwrap:before {
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.other-work-btnwrap:before {
	content: "";
	background-color: rgba(51, 51, 51, 0);
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	z-index: 0;
}

.other-work-btnwrap:hover:before {
	background-color: rgba(51, 51, 51, .5);
}

.other-work-btnwrap .btn {
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

@media (min-width: 768px) {

	.nav-doubleflip .icon-wrap {
		width: 10.6666rem;
	}

	.nav-doubleflip a.prev h3 {
		text-align: left;
	}

	.nav-doubleflip a.next h3 {
		text-align: right;
	}
}

@media (min-width: 992px) {

	.other-work nav a {
		margin-top: 4.5rem;
	}

	.nav-doubleflip .icon-wrap {
		width: 13.33333rem;
		height: 7.5rem;
	}
}

@media (min-width: 1200px) {

	.nav-doubleflip .icon-wrap {
		width: 15.2125rem;
		height: 9rem;
	}
}


/* Archives */

.posts-navigation a {
	line-height: 1.25;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;

	font-family: 'Montserrat';
	font-weight: 700;
	position: relative;
	text-transform: uppercase;
	z-index: 0;

	background: transparent;
	border: 1px solid #f5a623;
	color: #f5a623;

	padding: .75rem 1.5rem;
	font-size: 1.25rem;
}

.posts-navigation a:hover {
	text-decoration: none;

	background: #f5a623;
	border: 1px solid #f5a623;
	color: #fff;
}


/* Archive > Projects */

.archive-project,
.archive-project-image,
.archive-project-info,
.archive-project figure:hover figcaption,
.archive-project figure figcaption {
	height: 20rem;
}

.archive-project,
.archive-project figure {
	position: relative;
}

.archive-project {
	background: #333;
	background: -webkit-linear-gradient(#333 50%, #f5a623 0);
	background: linear-gradient(#333 50%, #f5a623 0);
	margin: 1.5rem 0;
	z-index: 0;
}

.archive-project-image {
	background-position: 50%;
	background-size: cover;
	z-index: 1;
}

.archive-project-image:before {
	background-color: rgba(0, 0, 0, .5);
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

figure:hover .archive-project-image:before {
	background-color: transparent;
}

.archive-project .logo-wrap {
	position: absolute;
	width: 100%;
	margin-left: -50%;
	left: 50%;
	top: 50%;
	opacity: 1;
}

.archive-project figure:hover .logo-wrap {
	opacity: 0;
}

.archive-project-logo {
	/*filter: brightness(0) invert(1);*/
	width: 100%;
	margin: 0 auto;
	display: block;
}

.archive-project figure .logo-wrap,
.archive-project figure .logo-wrap img,
figure .archive-project-image {
	-webkit-transition: all .4s;
	transition: all .4s;
}

.archive-project figure .logo-wrap,
.archive-project figure .logo-wrap img,
figure .archive-project-image {
	-webkit-transition: all .4s;
	transition: all .4s;
}

figure:hover .archive-project-image {
	-webkit-transform-origin: bottom left;
	transform-origin: bottom left;
	-webkit-transform: scale(.5);
	transform: scale(.5);
}

.archive-project figure figcaption {
	display: table;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: -1;
}

.archive-project figure:hover figcaption {
	z-index: 0;
}

.archive-project .project-info {
	color: #fff;
	padding: 1rem;
	position: relative;
	width: 100%;
}

.archive-project .project-info p {
	margin-bottom: 2.5rem;
}

.archive-project figure figcaption ul.services {
	position: absolute;
	right: 0;
	top: 50%;
	padding: .5rem;
	width: 50%;
}

.archive-project figure figcaption ul.services li {
	float: none;
	overflow: hidden;
	width: 100%;
}

.nav-links {
	overflow: hidden;
	padding: 1rem 0;
}

.nav-previous {
	float: left;
}

.nav-next {
	float: right;
}

@media (min-width: 768px) {

	.archive-project .logo-wrap {
		height: 5rem;
	}
	.archive-project-logo {
		height: 100%;
		width: auto;
	}
}

@media (max-width: 991px) {

	ul.services li h5 {
		font-size: .75rem;
		margin-top: .75rem;
	}
	.project-intro ul.services li h5,
	.project-intro ul.services li img {
		float: none;
	}
}

@media (min-width: 992px) {

	.archive-project {
		background: -webkit-linear-gradient(left, #333 50%, #f5a623 0);
		background: linear-gradient(90deg, #333 50%, #f5a623 0);
		margin: 2.5rem 0;
	}

	.archive-project,
	.archive-project-image,
	.archive-project-info,
	.archive-project figure:hover figcaption,
	.archive-project figure figcaption {
		height: 25rem;
	}

	figure:hover .archive-project-image {
		-webkit-transform-origin: bottom right;
	}

	.archive-project figure figcaption ul.services {
		padding: 0 1rem;
		top: 0;
	}

	.archive-project figure figcaption ul.services li {
		float: left;
		width: 33.333333%;
	}

	.archive-project .project-info {
		display: table-cell;
		height: 100%;
		padding: 2.5rem 5rem;
		vertical-align: middle;
		width: 50%;
	}
}


/* Archive - Clients */

.clients-overlay {
	background: #fff;
	margin-top: -7.5rem;
}

.search-item {
	border-bottom: 1px solid #cbcbcb;
}

.category-block {
	background: hsla(0,0%,100%,.5);
	box-shadow: 0 1px 4px 0 rgba(0,0,0,.33);
}

.category-block:hover {
	background: hsla(0,0%,100%,.75);
	text-decoration: none;
}

.category-block img {
	height: 3rem;
}