/************ 

Navigation */

header.banner {
  background: #333;
  background: linear-gradient(90deg,#393939 0%,#222 85%);
  box-shadow: 0 .125rem .125rem -.125rem rgba(0,0,0,.25);
  display: -webkit-flex;
  display: flex;
  font-family: 'Montserrat';
  /*position: relative; */
  z-index: 9;
  height: 3.75rem;
}

.brand {
  top: 1rem;
  left: 1rem;
  position: relative;
}

.brand img {
  height: 1.75rem;
}

@media (min-width: 992px) {
  .brand {
    top: 1.125rem;
    left: 2.175rem;
  }
}

@media (min-width: 1200px) {
  .brand {
    left: 2.75rem;
  }
}

/********************************************************* 

Mobile overlay - https://codepen.io/PaulVanO/pen/XJYGNQ */

@media (max-width: 991px) {

  .button_container {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 27px;
    width: 35px;
    cursor: pointer;
    z-index: 100;
    transition: opacity .25s ease;
  }

  .button_container.active .top {
    -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
    transform: translateY(11px) translateX(0) rotate(45deg);
    background: #f5a623;
  }

  .button_container.active .middle {
    opacity: 0;
    background: #f5a623;
  }

  .button_container.active .bottom {
    -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
    transform: translateY(-11px) translateX(0) rotate(-45deg);
    background: #f5a623;
  }

  .button_container span {
    background: #FFF;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .35s ease;
    cursor: pointer;
  }

  .button_container span:nth-of-type(2) {
    top: 11px;
  }

  .button_container span:nth-of-type(3) {
    top: 22px;
  }

  .overlay {
    position: fixed;
    background: #333;
    background: -webkit-linear-gradient(left, #393939, #222 85%);
    background: linear-gradient(90deg, #393939, #222 85%);
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity .35s, visibility .35s, height .35s;
    overflow: hidden;
  }

  .overlay.open {
    opacity: .9;
    visibility: visible;
    height: 100%;
  }

  .overlay.open li {
    -webkit-animation: fadeInRight .5s ease forwards;
    animation: fadeInRight .5s ease forwards;
    -webkit-animation-delay: .35s;
    animation-delay: .35s;
  }

  .overlay.open li:nth-of-type(2) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
  }

  .overlay.open li:nth-of-type(3) {
    -webkit-animation-delay: .45s;
    animation-delay: .45s;
  }

  .overlay.open li:nth-of-type(4) {
    -webkit-animation-delay: .50s;
    animation-delay: .50s;
  }

  .overlay nav {
    position: relative;
    height: 70%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 32px;
    text-align: center;
  }

  .overlay ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
  }

  .overlay ul li {
    display: block;
    height: 20%;
    height: calc(100% / 5);
    min-height: 50px;
    position: relative;
    opacity: 0;
  }

  .overlay ul li a {
    display: block;
    position: relative;
    color: #FFF;
    text-decoration: none;
    overflow: hidden;
  }

  .overlay ul li a:hover:after, 
  .overlay ul li a:focus:after, 
  .overlay ul li a:active:after {
    width: 100%;
  }

  .overlay ul li a:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 3px;
    background: #FFF;
    transition: .35s;
  }

  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }

}

/* End mobile overlay styles */

.nav-primary .menu-item {
  font-weight: 700;
  text-transform: uppercase;
}

.nav-primary .menu-item a {
  color: #fff;
  display: block;
  padding: 1rem;
}

.nav-primary .menu-item a:hover {
  text-decoration: none;
}

.dropdown-menu {
  background-color: #f5a623;
  border: 0;
  border-radius: 0;
  box-shadow: 0rem 1px 5px 0px rgba(0,0,0,.25);
  margin: 0;
  min-width: 9rem;
  width: 100%;
  padding: 0;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  header.banner {
    height: 4rem;
  }
}

@media (min-width: 992px) {

  header.banner {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .nav-primary {
    margin: 0 auto;
  }

  .navbar-toggleable-md.nav-primary .navbar-nav .menu-item a.nav-link {
    display: inline-block;
    font-size: 1rem;
    padding: 0;
  }

  /* https://tympanus.net/Development/CreativeLinkEffects/ */
  .nav-primary a {
    overflow: hidden;
    height: 1.1rem;
  }

  .nav-primary a:hover,
  .nav-primary a:focus {
    outline: none;
  }

  .nav-primary a span {
    padding: 0 1rem;
    position: relative;
    display: inline-block;
    -webkit-transition: -webkit-transform 0.2s;
    -moz-transition: -moz-transform 0.2s;
    transition: transform 0.2s;
  }

  .nav-primary a span::before {
    position: absolute;
    top: 100%;
    content: attr(data-hover);
    font-weight: 700;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .nav-primary a:hover span,
  .nav-primary a:focus span {
    color: #f5a623;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  .nav-primary .active.menu-item a {
    color: #e6940a;
  }

  .nav-primary .active.menu-item a:hover span,
  .nav-primary .active.menu-item a:focus span {
    color: #f5a623;
  }

  .brand,
  .header-contact {
    position: absolute;
  }

  .header-contact {
    right: 0;
    top: 0;
  }

}

@media (min-width: 1200px) {

  .nav-primary a span {
    padding: 0 3rem;
  }

}
