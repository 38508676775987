/* Homepage > Shared */

.split-width {
  margin: 0 auto;
  max-width: 50rem;
  padding: 2.5rem 0;
}

.split-width header {
  font-size: 1.5rem;
  font-weight: 600;
}

.split-screen-grey {
  background: #333333;
  color: #fff;
}

.section-lightgrey {
  background: #cbcbcb;
}

.split-screen-orange {
  background: #F5A623;
}

.split-screen-orange img {
  display: block;
  max-height: 5rem;
}

.split-screen-orange .btn {
  font-size: .875rem;
}

.split-screen-orange .btn-secondary:hover {
  color: #000;
}

.come-in {
  transform: translateY(150px);
  animation: come-in 0.8s ease forwards;
}
.come-in:nth-child(odd) {
  animation-duration: 0.6s;
}
.already-visible {
  transform: translateY(0);
  animation: none;
}

@keyframes come-in {
  to { transform: translateY(0); }
}

#what-we-do .split-screen-orange h4 {
  line-height: 5rem;
}

.section-intro p {
  font-weight: 600;
  letter-spacing: .03125rem;
}

.section-intro p:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {

  .split-screen-orange img,
  .split-screen-icons img {
    margin: 0 auto 2.5rem;
    max-height: 8rem;
  }

  .split-screen-orange ul h4,
  #what-we-do .split-screen-orange h4 {
    line-height: 1.1;
  }
  
  .split-screen-orange ul h4 {
    text-align: center;
  }
}

@media (min-width: 992px) {

  .split-width {
    padding: 5rem 1rem;
  }
  .split-width header {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  .split-width {
    padding: 5rem 2.5rem;
  }
}

@media (min-width: 1440px) {
  .split-width {
    padding: 5rem;
  }
}


/* Homepage > Home Hero Section */

.services-hero-box {
  background: none;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
}

.services-hero-box h1, 
.services-hero-box p {
  text-shadow: 1px 1px rgba(55,55,55,.5);
}

.services-hero-box h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.75rem;
}

.services-hero-box p {
  font-size: 1rem;
}

.hero-cell .btn-primary:hover,
.hero-cell .btn-primary:active {
  background: #232323;
  color: #f5a623;
}

.hero-cell .btn-primary:after {
  background: #232323;
}

.hero-cell .btn-secondary:hover,
.hero-cell .btn-secondary:active {
  background: #e6940a;
  color: #232323;
}

.hero-cell .btn-secondary {
  border: 1px solid #333;
}

.hero-cell .btn-secondary:after {
  background: #e6940a;
}

@media (min-width: 576px) {
  .services-hero-box {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .services-hero-box h1 {
    font-size: 3rem;
  }
}

@media (min-width: 992px) {

  .services-hero-box h1 {
    line-height: 1.35416667;
  }
  .services-hero-box p {
    font-size: 1.5rem;
    text-shadow: 1px 1px rgba(55, 55, 55, .5);
  }
}


/* Homepage > What We've Done Section */

.home-project,
.home-project-image,
.home-project-info,
.home-project figure:hover figcaption,
.home-project figure figcaption {
  height: 20rem;
}

.home-project {
  margin: 1.25rem 0;
  position: relative;
  z-index: 0;
}

.home-project figure {
  background: #333;
  margin: 0;
  position: relative;
}

.home-project-image {
  background-position: center center;
  background-size: cover;
  z-index: 1;
}

.home-project-image:before {
  background-color: rgba(0, 0, 0, .5);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

figure:hover .home-project-image:before {
  background-color: rgba(0, 0, 0, 0);
}

figure .home-project-image,
.home-project figure .logo-wrap,
.home-project figure .logo-wrap img {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

figure:hover .home-project-image {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.home-project .logo-wrap {
  height: 5rem;
  position: absolute;
  width: 100%;
  margin-left: -50%;
  left: 50%;
  top: 50%;
  opacity: 1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.home-project figure:hover .logo-wrap {
  opacity: 0;
}

.home-project-logo {
  height: 100%;
  margin: 0 auto;
  display: block;
}

.home-project figure figcaption {
  display: table;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.home-project figure:hover figcaption {
  height: 100%;
  z-index: 0
}

.home-project .project-info {
  background: #333;
  color: #fff;
  padding: 1rem;
  position: relative;
}

.home-project .project-info p {
  margin-bottom: 2.5rem;
}

.home-project figure figcaption ul.services {
  background: #F5A623;
  max-height: 5rem;
  padding: .5rem 0;
  position: absolute;
  top: 15rem;
}

.home-project figure figcaption ul.services li h5 {
  color: #000;
  font-size: .5rem;
}

@media (min-width: 768px) {

  .home-project {
    background: #333;
    background: linear-gradient(90deg, #333 50%, #f5a623 50%);
    margin: 2.5rem 0;
  }

  .home-project .project-info,
  .home-project figure figcaption ul.services {
    background: transparent;
    width: 50%;
  }

  .home-project .project-info {
    display: table-cell;
    height: 100%;
    padding: 2.5rem 5rem;
    vertical-align: middle;
  }

  .home-project figure {
    background: transparent;
  }

  .home-project figure figcaption ul.services {
    background: #F5A623;
    max-height: none;
    right: 0;
    top: 0;
    padding: 0 1rem;
  }

  .home-project figure figcaption ul.services li h5 {
    font-size: 1rem;
  }

  figure:hover .home-project-image {
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    -o-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@media (min-width: 992px) {
  .home-project,
  .home-project-image,
  .home-project-info,
  .home-project figure figcaption,
  .home-project figure:hover figcaption {
    height: 25rem;
  }
}


/* This is a different approach for displaying the project images - can delete other css when I'm done */

.home-portfolio-image {
  background-color: #333;
  background-position: center center;
  background-size: cover;
  color: #fff;
  /* position: relative; */
  text-align: center;
}

.home-portfolio-wrap {
  position: relative;
}

.home-portfolio-wrap .home-portfolio-image:before {
  content: "";
  background-color: rgba(51, 51, 51, .33);
  height: 100%;
}

.home-portfolio-wrap:nth-child(even) .home-portfolio-image {
  background-color: #f5a623;
}

.home-portfolio-image header {
  position: absolute;
  width: 100%;
  margin-left: -50%;
  left: 50%;
  top: 50%;
  opacity: 1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.home-portfolio-image .logo-wrap img {
  max-width: 100%;
  height: auto;
}

.small .home-portfolio-image .logo-wrap img {
  max-height: 3rem;
}

.medium .home-portfolio-image .logo-wrap img {
  max-height: 4rem;
}

.large .home-portfolio-image .logo-wrap img {
  max-height: 5rem;
}

@media (min-width: 768px) {

  .home-portfolio-wrap.small .home-portfolio-image {
    height: 18.6666667rem;
  }

  .home-portfolio-wrap.large .home-portfolio-image {
    height: 28rem;
  }
}

@media (min-width: 992px) {

  .portfolio-hover-wrapper {
    height: 100%;
    opacity: 0;
  }

  .portfolio-hover-wrapper:before {
    content: "";
    height: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
  }

  .portfolio-hover-wrapper,
  .portfolio-hover-wrapper:before {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  .home-portfolio-image:hover .portfolio-hover-wrapper {
    opacity: 1;
  }

  .home-portfolio-image:hover .portfolio-hover-wrapper:before {
    background-color: #595959;
    height: 100%;
  }

  .home-portfolio-wrap:nth-child(even) .home-portfolio-image:hover .portfolio-hover-wrapper:before {
    background-color: #cbcbcb;
    color: #333;
  }

  .portfolio-hover-wrapper a {
    position: relative;
    z-index: 1;
  }

  .portfolio-hover-wrapper a:hover {
    text-decoration: none;
  }

  .portfolio-hover-wrapper a .project-info {
    color: #fff;
  }

  .home-portfolio-wrap:nth-child(even) .portfolio-hover-wrapper a .project-info {
    color: #333;
  }

  .inner-project-wrap {
    /*display: table;*/
    height: 100%;
    width: 100%;
  }

  .inner-project-wrap .project-info {
    /*display: table-cell;*/
    vertical-align: middle;
  }

  .home-portfolio-wrap ul.services {
    padding: 2.5rem 0;
  }

  .home-portfolio-wrap:nth-child(odd) ul.services li img {
    filter: brightness(0) invert(1);
  }

  .inner-project-wrap .project-info .btn {
    border-color: #fff;
  }

  .inner-project-wrap .project-info .btn:after {
    height: 0;
    width: 100%;
    top: 0;
    left: 0;
  }

  .inner-project-wrap .project-info .btn:active:after,
  .inner-project-wrap .project-info .btn:hover:after {
    height: 100%;
  }

  .home-portfolio-wrap:nth-child(even) .inner-project-wrap .project-info .btn {
    border-color: #333;
  }

  .home-portfolio-wrap:nth-child(odd) .inner-project-wrap .project-info .btn:active,
  .home-portfolio-wrap:nth-child(odd) .inner-project-wrap .project-info .btn:hover {
    color: #595959;
  }

  .home-portfolio-wrap:nth-child(even) .inner-project-wrap .project-info .btn:active,
  .home-portfolio-wrap:nth-child(even) .inner-project-wrap .project-info .btn:hover {
    color: #cbcbcb;
  }

  .home-portfolio-wrap:nth-child(odd) .inner-project-wrap .project-info .btn:after {
    background: #fff;
  }

  .home-portfolio-wrap:nth-child(even) .inner-project-wrap .project-info .btn:after {
    background: #333;
  }

  .home-portfolio-wrap.small .home-portfolio-image {
    height: 21.333333rem;
  }

  .home-portfolio-wrap.large .home-portfolio-image {
    height: 32rem;
  }

}

@media (min-width: 1200px) {

  /* Adjusting Bootstrap embed styles for XL screens only */

  .embed-xl-responsive  {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .embed-xl-responsive:before {
    display: block;
    content: "";
  }

  .embed-xl-responsive-4by3:before {
    padding-top: 75%;
  }

  .embed-xl-responsive-16by9:before {
    padding-top: 56.25%;
  }

  .embed-xl-responsive .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .home-portfolio-wrap.small .home-portfolio-image,
  .home-portfolio-wrap.large .home-portfolio-image {
    height: initial;
  }

  .pb-3.home-portfolio-wrap:nth-child(4),
  .pb-3.home-portfolio-wrap:nth-child(5),
  .pb-3.home-portfolio-wrap:nth-child(6) {
    padding-bottom: 0 !important;
  }

}

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }
}

.flipInX {
  -webkit-backface-visibility: visible!important;
  backface-visibility: visible!important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}


/* Homepage > Client Section */

.col-logo {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.clients .logo-wrap img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
  object-fit: contain;
}

.clients .logo-wrap img {
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: grayscale(100%);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.clients .logo-wrap img:hover {
  -webkit-filter: none;
  filter: none;
}


/* Homepage > Map */

.contact-map,
.contact-map iframe {
  height: 18.75rem;
}

.contact-map iframe {
  width: 100%;
}

@media (min-width: 992px) {

  .contact-map,
  .contact-map iframe {
    height: 25rem;
  }
}


/**********

Page > General Styles **********/

.page-intro {
  padding: 5rem 0;
}

.hero-content {
  font-size: 1.25rem;
}

.hero-content h1,
.hero-content .h1,
.hero-content h2,
.hero-content .h2 {
  color: #f5a623;
}

.hero-content p {
  color: #fff;
}

@media (min-width: 768px) {

  .hero-split {
    background: rgba(51, 51, 51, .9);
    position: absolute;
    height: 100%;
    width: 50%;
  }

  .hero-content {
    background: transparent;
    font-size: 1.5rem;
    line-height: 1.54166666666667;
  }

  .content-cutout {
    margin-top: -5rem;
    position: relative;
    z-index: 1;
  }
}

@media (min-width: 1200px) {
  .content-cutout {
    margin-top: -3rem;
  }
}


/**********

Page > What We Do **********/

.what-we-do-services ul {
  margin-top: 6.3125rem;
  overflow: hidden;
  padding: 0;
  list-style: none;
}

.service-cell-wrap {
  background: rgba(255, 255, 255, .5);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.33);
}

.what-we-do-services .service-cell {
  border-bottom: 1px solid rgba(0, 0, 0, .333);
}

.what-we-do-services .service-cell a:hover {
  text-decoration: none;
}

.what-we-do-services .service-cell .btn-transparent {
  border: 1px solid #000;
  color: #000;
}

.what-we-do-services .service-cell .btn-transparent:hover {
  color: #fff;
}

.wwd-img-wrap,
.job-img-wrap {
  background: #f5a623;
  border: 2px solid;
  border-radius: 50%;
  height: 11.25rem;
  position: relative;
  width: 11.25rem;
}

.what-we-do-services .service-cell img,
.job-cell img {
  display: block;
  height: 7.25rem;
  width: 7.25rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.what-we-do-services .btn-secondary,
.bg-orange .btn-secondary,
.split-screen-orange .btn-secondary {
  background: #000;
}


/******************************

  Page > What We Do Sub Page

  ******************************/

  .services-image {
    background: #f5a623;
    border-radius: 50%;
    height: 12.5rem;
    overflow: hidden;
    position: relative;
    width: 12.5rem;
  }

  .services-image img {
    height: 8rem;
    width: 8rem;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: 992px) {

    .services-image {
      height: 18.75rem;
      width: 18.75rem;
    }

    .services-image img {
      height: 12rem;
      width: 12rem;
    }
  }


/********************

Page > Who We Are ********************/

.history-logos {
  background: #fff;
  margin-top: -7.5rem;
  position: relative;
  z-index: 1;
}

.history-logos img {
  display: block;
  margin: 0 auto;
}

.wwa-staff {
  background: #333;
  color: #fff;
}

.wwa-staff a {
  color: #f5a623;
}

.staff-photos {
  list-style: none;
  overflow: hidden;
  padding: 0;
}

.staff-photos li {
  background: #333;
  float: left;
  width: 50%;
}

.staff-photos li img {
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.staff-photos li:hover img {
  opacity: .75;
}

.staff-names {
  list-style: none;
}

.staff-names li a:hover {
  color: #e6940a;
  text-decoration: none;
}

.approach-timeline .col-4,
.approach-timeline .col-6,
.approach-timeline .col-8,
.approach-timeline .col-10,
.approach-timeline .col-12,
.approach-timeline .col-md-4,
.approach-timeline .col-md-6,
.approach-timeline .col-md-8,
.approach-timeline .col-md-12 {
  position: relative;
}

.approach-timeline .col-4:before,
.approach-timeline .col-6:before,
.approach-timeline .col-8:before,
.approach-timeline .col-md-4:before,
.approach-timeline .col-md-6:before,
.approach-timeline .col-md-8:before,
.approach-timeline .col-md-12:before {
  content: "";
  position: absolute;
  top: 4.5rem;
  left: 1.5rem;
  width: 100%;
  height: .25rem;
  background: #333;
  z-index: 0;
}

.approach-timeline .col-md-8:last-child .row .col-4.service-cell:before {
  width: 50%;
}

.what-we-do-services .approach-timeline .service-cell {
  margin-bottom: 0;
}

.approach-wrap {
  background: #fff;
  color: #333;
  position: relative;
  z-index: 1;
}

.is-selected .approach-wrap {
  background: #333;
  color: #fff;
}

.responsive-circle {
  position: relative;
  z-index: 1;
}

.responsive-circle:after {
  background: #EDEDED;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: "";
  display: block;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 100%;
  width: 100%;
}

.responsive-img-wrap {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.responsive-img-wrap:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.approach-timeline .service-cell {
  border-bottom: 0;
  color: #000;
  margin-bottom: 2.5rem;
  padding: 0 2.5rem;
  text-align: center;
}

.what-we-do-services .service-cell .responsive-img-wrap img {
  /* lazy css overwriting */
  height: 10.25rem;
  width: auto;
  padding: 1.5rem;
}

@media (max-width: 767px) {
  .approach-timeline .col-10.service-cell:before {
    top: -1rem;
    left: 50%;
    width: .25rem;
    height: 50%;
  }
}


@media (min-width: 768px) {

  .approach-timeline p {
    font-size: 1.5rem;
  }

  .what-we-do-services .service-cell .responsive-img-wrap img {
    padding: 2rem;
  }

  .staff-photos li {
    width: 33.3333%;
  }
}

@media (min-width: 1200px) {

  .staff-photos li {
    width: 25%;
  }

}

/* Testing this */

.timeline {
  margin-left: 10%;
}

.timeline:before {
  content: "";
  position: absolute;
  top: 2.5rem;
  left: 1.5rem;
  width: .25rem;
  height: calc(100% - 5rem);
  background: #f5a623;
}

.timeline .column .title h3 {
  color: rgba(255, 255, 255, 0.25);
  font-size: 4rem;
  letter-spacing: 3px;
  position: relative;
}

.timeline .column .title h3:before {
  content: "";
  position: absolute;
  left: -10.33rem;
  top: 5rem;
  width: 2.5rem;
  height: 2.5rem;
  background: url("/app/uploads/2017/09/favicon.png");
  background-repeat: no-repeat;
  background-size: 2rem;
  border: .25rem solid #f5a623;
  border-radius: 50%;
}

.timeline .column .title h4 {
  color: #f5a623;
  margin-top: -5.25rem;
  margin-bottom: 1rem;
}

.timeline .column .description {
  background: #fff;
}

.timeline .column .description p:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {

  .timeline:before {
    left: 2.5rem;
  }
  .timeline .column {
    margin: 0 2.5rem 2.5rem 5rem;
  }
}


/* Testimonials */

.carousel-indicators {
  bottom: -1rem;
}

.carousel-indicators li {
  background: #555;
}

.carousel-indicators li.active {
  background: #f5a623;
}

.testimonial-text {
  font-style: italic;
}

.testimonial-text h4,
.testimonial-text h5,
.testimonial-text p {
  padding-left: 1rem;
}

.testimonial-text p {
  border-left: 5px solid #f5a623;
  color: #444;
}

.testimonial-item {
  border-bottom: 1px solid rgba(55, 55, 55, .25);
  ;
}

.testimonial-item:last-child {
  border: 0;
}

/* Borrowing elements from here - https://htmlstream.com/preview/stream-ui-kit/ */

.blockquote-v1 {
  padding: 1.5rem 1.25rem;
  background-color: #f6f9fc;
  font-size: 1.25rem;
  font-weight: 300;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.blockquote-v1--left {
  border-left: solid 0.25rem #f5a623;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #1b1e24;
}

@media (min-width: 768px) {
  .testimonial-text p {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .testimonial-text {
    min-height: 24rem;
  }
}

@media (min-width: 1200px) {
  .testimonial-text {
    min-height: 19rem;
  }
}

/**********

  Page > What We've Done

  **********/

  .wwd-project,
  .wwd-project-image {
    height: 22rem;
  }

  .wwd-project-image {
    background-position: 50%;
    background-size: cover;
    z-index: 1;
  }

  .wwd-project-image:before {
    background-color: rgba(0, 0, 0, .5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  figure:hover .wwd-project-image {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  .wwd-project .logo-wrap {
    position: absolute;
    width: 100%;
    margin-left: -50%;
    left: 50%;
    top: 50%;
    opacity: 1;
  }

  .wwd-project-lg .logo-wrap {
    height: 5rem;
  }

  .wwd-project figure {
    background: #333;
    margin: 0;
    position: relative;
  }

  .wwd-project figure:hover .logo-wrap {
    opacity: 0;
  }

  .wwd-project figure .logo-wrap,
  .wwd-project figure .logo-wrap img,
  figure .wwd-project-image {
    -webkit-transition: all .4s;
    transition: all .4s;
  }

  .wwd-project figure .logo-wrap img {
    height: auto;
    width: 100%;
  }

  .wwd-project-lg figure .logo-wrap img {
    display: block;
    height: 100%;
    margin: 0 auto;
    width: auto;
  }

  .wwd-project figure figcaption {
    display: table;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .wwd-project figure:hover figcaption {
    height: 100%;
    z-index: 0;
  }

  .wwd-project .project-info {
    background: #333;
    color: #fff;
    padding: 1rem;
    position: relative;
  }

  .wwd-project .project-info h3 {
    margin-bottom: 1rem;
  }

  .wwd-project figure figcaption ul.services {
    background: #f5a623;
    margin: 0;
    max-height: 5rem;
    padding: .5rem 0;
    position: absolute;
    top: 17rem;

    width: 100%;
  }

  .wwd-project figure figcaption ul.services li img {
    height: 2rem;
  }

  .wwd-project figure figcaption ul.services li h5 {
    color: #000;
    font-size: .75rem;
  }

  .quotation-wrap {
    font-style: italic;
  }

  .quotation-wrap blockquote {
    border-left: 5px solid #f5a623;
    padding-left: 1rem;
  }

  .quotation-wrap h5 {
    padding-left: 1rem;
  }

  .bg-grey-dark .quotation-wrap a {
    color: #fff;
  }

  @media (min-width: 768px) {

    .wwd-portfolio .row {
      margin-right: -7.5px;
      margin-left: -7.5px;
    } 

    .home-portfolio-wrap {
      padding-right: 7.5px;
      padding-left: 7.5px;
    }
  }

  @media (min-width: 992px) {

    .wwd-project-lg,
    .wwd-project-lg .wwd-project-image {
      height: 36rem;
    }

    .wwd-project-lg,
    .wwd-project-lg .wwd-project-image,
    .wwd-project-info,
    .wwd-project-lg figure:hover figcaption,
    .wwd-project-lg figure figcaption {
      height: 36rem;
    }

    .wwd-project-lg figure:hover .wwd-project-image {
      -webkit-transform-origin: bottom right;
      transform-origin: bottom right;
      -webkit-transform: scale(.5);
      transform: scale(.5);
    }

    .wwd-project-lg .project-info {
      width: 50%;
      height: 100%;
    }

    .wwd-project-lg figure figcaption ul.services {
      height: 50%;
      max-height: none;
      padding: 1rem;
      text-align: left;
      left: 50%;
      top: 0;
      width: 50%;
    }

    .wwd-project-lg figure figcaption ul.services li {
      width: 100%;
    }

    .wwd-project-lg figure figcaption ul.services li img,
    .wwd-project-lg figure figcaption ul.services li h5 {
      float: left;
    }

    .wwd-project-lg figure figcaption ul.services li img {
      height: 4rem;
      margin-right: 1rem;
      margin-bottom: 2rem;
    }

    .wwd-project-lg figure figcaption ul.services li:last-child img {
      margin-bottom: 0;
    }

    .wwd-project-lg figure figcaption ul.services li h5 {
      font-size: 1rem;
    }

  }

/* --------------------------------

*** Link: https://codyhouse.co/gem/vertical-timeline/ ***

Modules - reusable parts of our design - borrowing from Fine Gael site

-------------------------------- */

#cd-timeline {
  position: relative;
  overflow: hidden;
}

#cd-timeline::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 15rem;
  left: 2.25rem;
  height: calc(100% - 5rem);
  width: .25rem;
  background: #f5a623;
}

.cd-timeline-block {
  position: relative;
  margin: 2rem 0;
}

.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.cd-timeline-block:first-child {
  margin-top: 0;
}

.cd-timeline-block:last-child {
  margin-bottom: 0;
}

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  border: .25rem solid #f5a623;
  border-radius: 50%;
  background: #333;
  overflow: hidden;
}

.cd-timeline-img img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -50%;
  margin-top: -50%;
}

.cd-timeline-img.cd-picture {
  background: #75ce66;
}

.cd-timeline-img.cd-movie {
  background: #c03b44;
}

.cd-timeline-img.cd-location {
  background: #f0ca45;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.cd-timeline-content {
  position: relative;
  margin-left: 3.75rem;
  background: #fff;
  border-radius: 0.25em;
  padding: 1rem;
  box-shadow: 0 0.25rem 0 rgba(255, 255, 255, .25);
}

.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  bottom: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #F2F2F2;
}

.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

.cd-timeline-content p,
.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
  font-size: 14px;
  font-size: .875rem;
}

.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
  display: inline-block;
}

.cd-timeline-content h3 {
  font-size: 2rem;
  letter-spacing: 3px;
  margin-bottom: 0;
  position: relative;
}

.cd-timeline-content h4 {
  color: #f5a623;
}

.cd-timeline-content p {
  line-height: 1.6;
}

.cd-timeline-content p:last-child {
  margin-bottom: 0;
}

.cd-timeline-content .cd-read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: 0.25em;
}

.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}

.cd-timeline-content .cd-date {
  float: left;
  padding: .8em 0;
  opacity: .7;
}

.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (min-width: 768px) {
  #cd-timeline::before {
    left: 58px;
  }
}

@media (min-width: 992px) {

  .cd-timeline-block {
    margin: -1rem 0;
  }

  .cd-timeline-block:first-child {
    margin-top: 0px;
  }

  .cd-timeline-img {
    top: 50%;
    left: 50%;
    /* Force Hardware Acceleration in WebKit */
    transform: translateZ(0px);
    backface-visibility: hidden;
    width: 2.5rem;
    height: 2.5rem;
    left: 50%;
    margin-top: -0.75rem;
    margin-left: -1.25rem;
  }

  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 0.6s;
  }

  .cd-timeline-content {
    box-shadow: -0.25rem 0.25rem 0 rgba(255, 255, 255, .25);
    margin-left: 0px;
    width: 47.5%;
    padding: 20px 30px;
  }

  .cd-timeline-content::before {
    top: 1.5rem;
    left: 100%;
    border-color: transparent transparent transparent #fff;
  }

  .cd-timeline-content h3 {
    color: rgba(255, 255, 255, 0.25);
    font-size: 4rem;
    margin-top: -6rem;
  }

  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }

  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    animation: cd-bounce-2 0.6s;
  }

  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }

  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }

  .cd-timeline-block:first-child {
    margin-top: 0;
  }

  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }

  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }

  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }

  .cd-timeline-content {
    margin-left: 0;
    padding: 1rem;
    width: 45%;
  }

  .cd-timeline-content::before {
    top: 50%;
    left: 100%;
    border-color: transparent;
    border-left-color: #fff;
  }

  .cd-timeline-content .cd-read-more {
    float: left;
  }

  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
    box-shadow: 0.25rem 0.25rem 0 rgba(255, 255, 255, .25);
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 50%;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #fff;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }

  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
}

/*** End Timeline Bounce code ***/


/*! Flickity v2.0.10
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}


/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}


/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  /* Fusio edited/ amended style */
  width: 2rem;
  height: 2rem;
  border: none;
  border-radius: 50%;
  background: white;
  background: #333;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
  /* Fusio edited/ amended styles */
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.what-we-do-services .flickity-prev-next-button {
  animation: pulse 2s infinite;
  top: 4.25rem;
}

.flickity-prev-next-button:hover {
  background: white;
}

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.what-we-do-services .flickity-prev-next-button.previous {
  left: 10px;
}

.what-we-do-services .flickity-prev-next-button.next {
  right: 10px;
}

.testimonials .flickity-prev-next-button {
  top: 10rem; 
}

.testimonials .flickity-prev-next-button.previous {
  left: 0;
}

.testimonials .flickity-prev-next-button.next {
  right: 0;
}


/* right to left */

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #f5a623;
  /* Fusio edited style */
}


/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}


/* Fusio edited/ amended styles */

.carousel-cell {
  width: 66%;
  height: 415.73px;
  margin-right: 10px;
  border-radius: 5px;
  counter-increment: gallery-cell;
  opacity: .75;
}

.carousel-cell.is-selected {
  opacity: 1;
}

.flickity-prev-next-button:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all .2s;
  transition: all .2s;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  background: #e6940a;
  border-radius: 50%;
}

.flickity-prev-next-button:hover {
  background: #333;
}

.flickity-prev-next-button:hover:after {
  width: 100%;
}

.flickity-prev-next-button:hover .arrow {
  fill: #333;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    transform: scale(1);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    transform: scale(1.1);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .carousel-cell {
    width: 66%;
    height: 268.19px;
  }
  .flickity-prev-next-button {
    top: 4.1rem;
    /* Fusio edited/ amended style */
    width: 3rem;
    height: 3rem;
  }
}

/*
Default style for WP-PageNavi plugin

http://wordpress.org/extend/plugins/wp-pagenavi/
*/

.wp-pagenavi {
  clear: both;
}

.wp-pagenavi a, .wp-pagenavi span {
  text-decoration: none;
  border: 1px solid #f5a623;
  padding: .5rem .75rem;
  margin: .125rem;
}

.wp-pagenavi a {
  background: #f5a623;
  color: #000;
  font-weight: 700;
}

.wp-pagenavi span {
  border: 1px solid rgba(0,0,0,.125);
}

.wp-pagenavi a:hover, .wp-pagenavi span.current {
  border-color: #f5a623;
}

.wp-pagenavi a:hover {
  background-color: #fff;
}

.wp-pagenavi span.current {
  font-weight: 700;
}
