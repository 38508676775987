.home-hero-section,
.hero-section {
  background-size: cover;
  height: 26.25rem;
  position: relative;
  z-index: 0;
}

.home-hero-section,
.hero-section {
  background-position: 50%;
}

.hero-section:before {
  background: -webkit-linear-gradient(left, rgba(57,57,57,.75), rgba(34,34,34,.75) 85%);
  background: linear-gradient( 90deg, rgba(57,57,57,.75), rgba(34,34,34,.75) 85%);
  content: '';
  display: block;
  height: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}


.home-hero-section p,
.hero-section p {
  margin-bottom: 0;
}

.hero-section-split {
  height: 24rem;
  position: relative;
  z-index: 0;
}

.hero-section-split .bg-split {
  background-size: cover;
}

@media (min-width: 768px) {

  .home-hero-section,
  .hero-section {
    height: 31.25rem;
  }

  .home-hero-section p {
    font-size: 1.5rem;
  }

  .home-hero-section .btn {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
  }

}

@media only screen and (max-device-width : 767px) and (orientation : landscape) { 

  .home-hero-section,
  .hero-section {
    height: 100vh;
  } 

}

@media (min-width: 992px) {

  .home-hero-section,
  .hero-section {
    height: 37.5rem;
  }

}

.home-hero-section .services-hero-box {
  animation: moveInLeft 1s ease-out;
}

.home-hero-section .about-hero-box {
  animation: moveInRight 1s ease-out;
}

/* Animations for hero section */

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
