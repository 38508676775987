/***** Colours *****/

/* Dark Grey - #333 / rgb(55, 55, 55); /*
/* Orange - #f5a623 / rgb(245, 166, 35) */

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,600i,900|Montserrat:300,500,700');

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	color: #333;
	font-family: 'Source Sans Pro', sans-serif;
	text-rendering: optimizelegibility;
}

/* General > Links */

a {
	color: #014099;
	letter-spacing: .025rem;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

a:focus,
a:hover {
	color: #333;
}

/* General > Headings */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: 'Montserrat', sans-serif;
}

h2 {
	font-size: 2rem;
}

h3 {
	font-size: 1.5rem;
}

h4 {
	font-size: 1.25rem;
}

h5 {
	font-size: 1rem;
}

h6 {
	font-size: .75rem;
}

@media (min-width: 992px) {
	h1 {
		font-size: 4rem;
		line-height: 1.34375;
	} 

	h2,
	.h2 {
		font-size: 3rem;
		line-height: 1.275;
	}

	h3 {
		font-size: 2rem;
		line-height: 1.4375;
	}

	h4 {
		font-size: 1.25rem;
		line-height: 1.65;
	}

	h5 {
		font-size: 1rem;
		line-height: 1.875;
	}

	h6 {
		font-size: .75rem;
		line-height: 2.25;
	}
}

/* General > Typography */

.font-source-sans-pro {
	font-family: 'Source Sans Pro', sans-serif;
}

.font-light {
	font-weight: 300;
}

.font-bold,
.text-bold {
	font-weight: 700;
}

.text-black {
	color: #000!important;
}

.text-darkgrey {
	color: #333;
}

.text-orange {
	color: #f5a623!important;
}

.text-shadow {
	text-shadow: 1px 1px rgba(55,55,55,.5);
}

.text-nounderline,
.text-nounderline:hover {
	text-decoration: none !important;
}

@media (min-width: 992px) {
	p.intro {
		margin-bottom: 2.5rem;
	}
}


/* General > Background Styles */

.bg-darkgrey,
.bg-grey-dark,
.bg-brand02 {
	background-color: #333;
}

.bg-darkgrey-opaque,
.bg-grey-dark-opaque {
	background-color: rgba(51,51,51,.8);
}

.bg-lightgrey,
.bg-grey-light {
	background-color: #cbcbcb;
}

.bg-orange {
	background-color: #f5a623;
}

.bg-orange-opaque {
	background-color: rgba(245,166,35,.8);
}

.bg-white {
	background-color: #fff;
}

.bg-white-opaque {
	background-color: rgba(255,255,255,.8)
}

.bg-split-grey-orange {
	background: linear-gradient(90deg, #333 50%, #f5a623 50%);
}

.bg-cover {
	background-size: cover;
	background-repeat: no-repeat;
}

.bg-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}

.bg-overlay.medium {
	opacity: 0.5;
}

.box-shadow {
	box-shadow: 0 0.0625rem 0.0625rem 0.0625rem rgba(0,0,0,.25);
}

.box-shadow-inset {
	box-shadow: inset 0 0.0625rem 0.0625rem -0.0625rem rgba(0,0,0,.25);
}

/* General > Content */

.wrap .content .main {
  line-height: 1.6875;
}

/* General */

.full-width {
  padding: 2.5rem 0;
}

@media (min-width: 768px) {

  .full-width {
    padding: 5rem 0;
  }

  .row-grid+.row-grid {
    margin-top: 2.5rem;
  }
}
