.btn,
.btn-lg {
  border-radius: 0;
}

.btn {
  font-family: 'Montserrat';
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  z-index: 0;
}

.btn.focus,
.btn:focus,
.comment-form input.focus[type=submit],
.comment-form input[type=submit]:focus,
.search-form .focus.search-submit,
.search-form .search-submit:focus {
  box-shadow: none;
}

.btn:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.btn-primary {
  background: #F5A623;
  border-color: transparent;
  color: #232323;
}

.btn-primary:hover,
.btn-primary:active {
  background: #fff;
  border-color: #F5A623;
  color: #232323;
}

.btn-primary:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: #fff;
}

.btn-primary:hover:after,
.btn-primary:active:after {
  height: 100%;
}

.btn-secondary {
  background: #333;
  border: 1px solid #000;
  color: #f5a623;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  background: transparent;
  border: 1px solid #000;
  color: #000;
}

.btn-secondary:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: #f5a623;
}

.btn-secondary:hover:after,
.btn-secondary:active:after,
.btn-secondary:focus:after {
  height: 100%;
}

.btn-white {
  border: 1px solid #fff;
  border-radius: 0;
  color: #fff;
}

.btn-white-opaque {
  border: 1px solid rgba(255,255,255,.5);
}

.btn-white:hover,
.btn-white:active {
  color: #333;
}

.btn-white:after,
.btn-white-opaque:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: #fff;
}

.btn-white:hover:after,
.btn-white:active:after,
.btn-white-opaque:hover::after,
.btn-white-opaque:active::after {
  height: 100%;
}

.btn-transparent {
  border: 1px solid #333;
  color: #333;
}

.btn-transparent:hover,
.btn-transparent:active {
  color: #fff;
}

.btn-transparent:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: #333;
}

.btn-transparent:hover:after,
.btn-transparent:active:after {
  height: 100%;
}

.btn-group,
.btn-group-vertical {
  display: block;
}

.btn-group>.btn:first-child {
  margin-right: 2rem;
}

.btn-contact {
  background: #f5a623;
  cursor: pointer;
  font-size: 2rem;
  height: 4rem;
  width: 100%;
}

.btn-contact:after {
  top: 1.5rem;
}

.btn-contact:hover,
.btn-contact[aria-expanded="true"] {
  background: #e6940a
}

.btn-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.btn-email {
  text-transform: lowercase;
  padding: .5rem 0;
}

@media (min-width: 768px) {

  .btn-group,
  .btn-group-vertical {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (min-width: 992px) {

  .btn-contact {
    font-size: 1rem;
    padding: .5rem 1rem .5rem .25rem;
    width: 9rem;
  }

  .h-lg-100 {
    height: 100%;
  }

}

@media (min-width: 1200px) {

  .btn-contact,
  .dropdown-item {
    width: 10rem;
  }

}